import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripe_pk =
  process.env.GATSBY_APP_ENV === "production"
    ? "pk_live_51IbEGdBwTb4sQIPpLRjgSJe1bbtbPhAtpua4IbtE9L9LKAE7NXqy3yyMIq3IJuQp1XrMJD3MUGA9lRd1F7Xv4RK100BaO0QDbc"
    : "pk_test_51IbEGdBwTb4sQIPpg92szUmRFSe567Hu6s2g6ldyeBYdThqLIMmVZR9SCLzVNyWkgFhAFkgxSgp6oiMPEQuL8mzV00kpZXDhN9";

class Stripe {
  constructor(stripeAccount) {
    // if (typeof Stripe.instance === "object") {
    //   return Stripe.instance;
    // }

    Stripe.instance = loadStripe(stripe_pk, { stripeAccount });
    return Stripe.instance;
  }
}

const StripeProvider = ({ options, children, paymentTypeDetails }) => {
  const stripePromise = new Stripe(paymentTypeDetails.paymentAppId);

  return stripePromise ? (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  ) : null;
};

export default StripeProvider;
